<template>
    <div>
        <FormulateForm name="contact-form-objection" v-model="formValues" :keep-model-data="true" ref="form">
            <div v-if="!success">
                <p>{{ translations.required_fields }}</p>
            </div>

            <!-- Success message -->
            <div v-if="verify && success" class="success-message" role="status">
                <p>{{ translations.success_message_objection }}</p>
            </div>

            <!-- Error message -->
            <div v-if="verify && !success && errors.length > 0" class="errors-message" role="alert">
                <p>{{ translations.error_message }}</p>
            </div>

            <div class="form-group" v-if="!success && !verify">
                <p class="label-section">
                    {{ translations.mark_your_choice }}
                </p>
                <FormulateInput
                    name="i_object"
                    ref="i_object"
                    type="checkbox"
                    :label="translations.objection"
                    label-position="before"
                    validation="required"
                    :validation-messages="{
                        required: translations.validation_i_object_required,
                    }"
                    :wrapper-class="['terms']"
                    :outer-class="['terms-input']"
                    :options="{ objection: translations.i_object }"
                />
                <FormulateInput
                    name="initials"
                    ref="initials"
                    :label="translations.initials"
                    validation="required"
                    :validation-messages="{
                        required: translations.validation_initials_required,
                    }"
                />
                <FormulateInput
                    name="surname"
                    ref="surname"
                    :label="translations.surname"
                    validation="required"
                    :validation-messages="{
                        required: translations.validation_surname_required,
                    }"
                    autocomplete="family-name"
                />
                <FormulateInput
                    name="birthdate"
                    ref="birthdate"
                    type="date"
                    :label="translations.birthdate"
                    validation="required|date:YYYY-MM-DD"
                    :validation-messages="{
                        required: translations.validation_birthdate_required,
                        date: '',
                    }"
                    :max="$date().format('YYYY-MM-DD')"
                    autocomplete="bday"
                />
                <FormulateInput
                    name="bsn"
                    ref="bsn"
                    :label="translations.bsn_last_3"
                    validation="required|number|max:3,length"
                    :validation-messages="{
                        required: translations.validation_bsn_required,
                        number: translations.validation_bsn_number,
                        max: translations.validation_bsn_max,
                    }"
                    autocomplete="off"
                />
                <FormulateInput
                    name="patient_number"
                    ref="patient_number"
                    :label="translations.patient_number"
                    validation="required|max:12,length"
                    :validation-messages="{
                        max: translations.validation_patient_number_required,
                        required: translations.validation_patient_number_max,
                    }"
                    :help="translations.patient_number_help"
                />
                <FormulateInput
                    name="email"
                    ref="email"
                    :label="translations.email"
                    validation="required|email"
                    :validation-messages="{
                        required: translations.validation_email_required,
                        email: translations.validation_email_email,
                    }"
                    autocomplete="email"
                    :help="translations.email_help_alt"
                />
                <p>
                    {{ translations.filling_in_for_child }}
                </p>
                <FormulateInput
                    name="filling_in_for_child"
                    ref="filling_in_for_child"
                    :options="{ no: translations.option_no, yes: translations.option_yes }"
                    type="select"
                    :label="translations.objection_for_child"
                    validation="required"
                />

                <template v-if="showFormgroup">
                    <FormulateInput
                        type="group"
                        name="children"
                        :repeatable="true"
                        :add-label="translations.add_child"
                        validation="optional"
                        :minimum="0"
                        :wrapper-class="['repeatable-group']"
                    >
                        <template #remove="{ index, removeItem }">
                            <div v-if="faultyChildren" role="alert" class="input-errors">
                                <ul class="formulate-form-errors">
                                    <li role="alert" class="formulate-form-error">
                                        {{ translations.children_older_than_15 }}
                                    </li>
                                </ul>
                            </div>
                            <div class="child">
                                <div class="child-index">
                                    <span>{{ translations.child }} {{ index + 1 }}</span>
                                    <a class="formulate-input-group-repeatable-remove" @click="removeItem">
                                        {{ translations.remove_child }}
                                    </a>
                                </div>
                                <FormulateInput
                                    name="initials"
                                    ref="initials_child"
                                    :label="translations.initials"
                                    validation="required"
                                    :validation-messages="{
                                        required: translations.validation_initials_required,
                                    }"
                                />
                                <FormulateInput
                                    name="surname"
                                    ref="surname_child"
                                    :label="translations.surname"
                                    validation="required"
                                    :validation-messages="{
                                        required: translations.validation_surname_required,
                                    }"
                                    autocomplete="family-name"
                                />
                                <FormulateInput
                                    name="birthdate"
                                    ref="birthdate_child"
                                    type="date"
                                    :label="translations.birthdate"
                                    :validation-messages="{
                                        required: translations.validation_birthdate_required,
                                        date: '',
                                    }"
                                    validation="required|date:YYYY-MM-DD"
                                    :max="$date().format('YYYY-MM-DD')"
                                />
                            </div>
                        </template>
                    </FormulateInput>
                </template>
                <FormulateInput
                    name="date"
                    ref="date"
                    type="date"
                    :label="translations.date"
                    validation="required|date:YYYY-MM-DD"
                    :validation-messages="{
                        required: translations.validation_date_required,
                        date: '',
                    }"
                    :max="$date().format('YYYY-MM-DD')"
                />
                <FormulateInput
                    name="place"
                    ref="place"
                    :label="translations.place"
                    validation="required"
                    :validation-messages="{
                        required: translations.validation_place_required,
                    }"
                />
                <div class="form-buttons">
                    <FormulateInput type="button" @click="runValidation">
                        {{ translations.verify_your_information }}
                    </FormulateInput>
                </div>
            </div>

            <div class="scroll-container" :class="{ check: verify && !success }" v-if="verify">
                <h4 class="form-check-title">
                    {{ translations.mark_your_choice }}
                </h4>
                <dl class="form-check">
                    <div class="item">
                        <dt>{{ translations.objection }}</dt>
                        <dd>
                            {{ translations.i_object.replace(/(<([^>]+)>)/gi, '') }}
                        </dd>
                    </div>
                    <div class="item">
                        <dt>{{ translations.initials }}</dt>
                        <dd>{{ formValues.initials }}</dd>
                    </div>
                    <div class="item">
                        <dt>{{ translations.surname }}</dt>
                        <dd>{{ formValues.surname }}</dd>
                    </div>
                    <div class="item">
                        <dt>{{ translations.birthdate }}</dt>
                        <dd>{{ convertDate(formValues.birthdate) }}</dd>
                    </div>
                    <div class="item">
                        <dt>{{ translations.bsn_last_3 }}</dt>
                        <dd>{{ formValues.bsn }}</dd>
                    </div>
                    <div class="item">
                        <dt>{{ translations.patient_number }}</dt>
                        <dd>{{ formValues.patient_number }}</dd>
                    </div>
                    <div class="item">
                        <dt>{{ translations.email }}</dt>
                        <dd>{{ formValues.email }}</dd>
                    </div>
                    <template v-if="showFormgroup && formValues.children.length > 0">
                        <template v-for="(child, index) in formValues.children" :key="index">
                            <div class="title">{{ translations.child }} {{ index + 1 }}</div>
                            <div class="item">
                                <dt>{{ translations.initials }}</dt>
                                <dd>{{ child.initials }}</dd>
                            </div>
                            <div class="item">
                                <dt>{{ translations.surname }}</dt>
                                <dd>{{ child.surname }}</dd>
                            </div>
                            <div class="item">
                                <dt>{{ translations.birthdate }}</dt>
                                <dd>{{ convertDate(child.birthdate) }}</dd>
                            </div>
                        </template>
                    </template>
                    <div class="item">
                        <dt>{{ translations.date }}</dt>
                        <dd>{{ convertDate(formValues.date) }}</dd>
                    </div>
                    <div class="item">
                        <dt>{{ translations.place }}</dt>
                        <dd>{{ formValues.place }}</dd>
                    </div>
                </dl>

                <p class="details-warning" v-if="!success">{{ translations.make_sure_details_are_the_same }}</p>

                <div class="form-buttons" v-if="!success">
                    <div>
                        <FormulateInput type="button" @click="verify = false" :disabled="loading" data-secondary>
                            {{ translations.edit }}
                        </FormulateInput>
                        <FormulateInput type="button" @click="submit" :disabled="loading">
                            {{ translations.submit }} <span v-if="loading" class="loader"></span>
                        </FormulateInput>
                    </div>
                </div>
            </div>
        </FormulateForm>
    </div>
</template>

<script>
import { getApiRoute } from './helpers/api.js';

export default {
    name: 'FormObjection',
    data() {
        return {
            verify: false,
            loading: false,
            success: false,
            errors: [],
            faultyChildren: false,
            formValues: {
                i_object: '',
                initials: '',
                surname: '',
                birthdate: '',
                bsn: '',
                patient_number: '',
                email: '',
                filling_in_for_child: '',
                children: [
                    {
                        initials: '',
                        surname: '',
                        birthdate: '',
                    },
                ],
                date: '',
                place: '',
            },
        };
    },
    computed: {
        translations() {
            const currentLanguage = document.getElementsByTagName('html')[0].getAttribute('lang').substring(0, 2);

            return currentLanguage === 'en' ? WP.en : WP.nl;
        },
        showFormgroup() {
            // Reinitialize Foundation so that Toggler works correctly.
            $(document).foundation();

            return this.formValues.filling_in_for_child !== 'no';
        },
    },
    watch: {
        formValues: {
            deep: true,
            handler(newFormValues, oldFormValues) {
                // Save form values to localStorage on every change.
                this.saveStorage(newFormValues);
                // console.log(newFormValues);
                this.faultyChildren = false;
            },
        },
    },
    created() {
        // Load stored form values on loading form.
        const storedFormValues = this.openStorage();
        if (storedFormValues) {
            this.formValues = storedFormValues;
        }
    },
    mounted() {
        // Reinitialize Foundation so that Toggler works correctly.
        $(document).foundation();
    },
    methods: {
        async submit() {
            // Prevent form from executing multiple times.
            if (this.loading === true) {
                return;
            }

            // Set states.
            this.loading = true;
            this.success = false;

            // Submit form.
            try {
                let form = {
                    ...this.formValues,
                    birthdate: this.convertDate(this.formValues.birthdate),
                    date: this.convertDate(this.formValues.date),
                };
                if (this.formValues.filling_in_for_child !== 'no') {
                    if (this.formValues.children.length > 0) {
                        const children = this.formValues.children.map((child) => ({
                            ...child,
                            birthdate: this.convertDate(child.birthdate),
                        }));
                        form = {
                            ...form,
                            children,
                        };
                    }
                }

                const { data } = await this.axios.post(getApiRoute('objection-form'), {
                    form,
                    translations: this.translations,
                });

                if (data.success) {
                    // Set states.
                    this.success = true;
                    $(document).trigger('ggd_form_submitted');

                    // Clear form and localStorage.
                    this.clearFormValues();
                } else {
                    // Set states.
                    this.errors = data.data;
                }
            } catch (error) {
                // Set states.
                this.errors = [error];
            } finally {
                // Set states.
                this.loading = false;

                // Scroll to success or errors message.
                this.$refs.form?.$el.scrollIntoView({ behavior: 'smooth' });
            }
        },
        async runValidation() {
            if (this.formValues.filling_in_for_child !== 'no') {
                if (this.formValues.children.length > 0) {
                    this.faultyChildren = this.formValues.children.some((child) => this.getAge(child.birthdate) >= 16);
                    if (this.faultyChildren) {
                        return;
                    }
                }
            }

            const form = this.$formulate.registry.get('contact-form-objection');
            form.formSubmitted();

            const hasErrors = await form.hasValidationErrors();
            if (!hasErrors) {
                this.verify = true;
            }
        },
        openStorage() {
            return JSON.parse(localStorage.getItem('formObjectionValues'));
        },
        saveStorage(form) {
            localStorage.setItem('formObjectionValues', JSON.stringify(form));
        },
        clearFormValues() {
            // this.formValues = {};
            localStorage.removeItem('formObjectionValues');
        },
        convertDate(dateString) {
            if (!dateString || dateString.length === 0) {
                return '';
            }

            const p = dateString.split(/\D/g);

            return [p[2], p[1], p[0]].join('-');
        },
        getAge: (dateString) => Math.floor((new Date() - new Date(dateString).getTime()) / 3.15576e10),
    },
};
</script>
